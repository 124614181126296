<template>
  <Dialog class="ticket-detail-dialog"
          v-model:visible="computedVisible"
          :modal="true"
          :closable="true">
    <template #header>
      <div class="title">{{ this.header.number }}</div>
      <div class="title">{{ this.header.ticket }}</div>
    </template>
    <div class="grid">
      <div class="card">
        <div id="overlay" v-if="loading2"></div>


        <!--        <Accordion :activeIndex="1" class="detail-accordion">-->
        <!--          <AccordionTab header="Agencia">-->
        <!--            <h1 v-if="data">-->
        <!--              <div>-->
        <!--                <div class="grid p-fluid">-->

        <!--                  <div class="col-12 md:col-6">-->
        <!--                    <div class="p-inputgroup">-->
        <!--                      <Button label="Fecha"/>-->
        <!--                      <div class="details">{{ data[0] && formatDate(data[0].fdesde) }}</div>-->
        <!--                    </div>-->
        <!--                    <div class="p-inputgroup">-->
        <!--                      <Button label="Hora"/>-->
        <!--                      <div class="details">{{ data[0] && formatTime(data[0].hora) }}</div>-->
        <!--                    </div>-->
        <!--                    <div class="p-inputgroup">-->
        <!--                      <Button label="Serial"/>-->
        <!--                      <div class="details">-</div>-->
        <!--                    </div>-->
        <!--                    <div class="p-inputgroup">-->
        <!--                      <Button label="Estatus"/>-->
        <!--                      <div class="details">{{ data[0].tstatus }}</div>-->
        <!--                    </div>-->
        <!--                  </div>-->

        <!--                  <div class="col-12 md:col-6">-->
        <!--                    <div class="p-inputgroup">-->
        <!--                      <Button label="Punto de Venta" class="p-button-secondary"/>-->
        <!--                      <div class="details">{{ data[0].tagencia }}</div>-->
        <!--                    </div>-->
        <!--                    <div class="p-inputgroup">-->
        <!--                      <Button label="Estado" class="p-button-secondary"/>-->
        <!--                      <div class="details">{{ data[0].testado }}</div>-->
        <!--                    </div>-->
        <!--                    <div class="p-inputgroup">-->
        <!--                      <Button label="Municipio" class="p-button-secondary"/>-->
        <!--                      <div class="details">{{ data[0].tmunicipio }}</div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </h1>-->
        <!--          </AccordionTab>-->
        <!--          <AccordionTab header="Detalles">-->
        <div class="flex justify-content-end">
          <Button label="Ver ticket" icon="pi pi-eye" iconPos="left" @click="openTicket()"
                  class="view-button"/>
        </div>

        <DataTable :value="data"
                   class="p-datatable-sm p-datatable-gridlines p-datatable-striped"
                   :loading="loading1"
                   :paginator="true"
                   :rows="10"
                   :footer="getTotals"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]" #
                   currentPageReportTemplate="{first} al {last} de {totalRecords}">
          <ColumnGroup type="header">
            <Row>
              <Column header="Sorteo" :sortable="true" field="tproductoxsorteo" bodyStyle="text-align: left"/>

              <h1 v-if="game === 'lottery'">
                <Column header="Número" :sortable="true" field="numero" bodyStyle="text-align: center"
                        footerStyle="text-align: center" footer="TOTAL:"/>
              </h1>
              <h1 v-else>
                <Column header="Animalito" :sortable="true" field="animal" bodyStyle="text-align: center"
                        footerStyle="text-align: center" footer="TOTAL:">
                  <template #body="{data}">
                    {{ data.animal }}
                  </template>
                </Column>
              </h1>

              <Column header="Monto" :sortable="true" field="mmonto_jugado" bodyStyle="text-align: right"
                      footerStyle="text-align: right"
                      :footer="formatCurrency(getTotals.monto)"/>
              <h1 v-if="!game">
                <Column header="Lista" :sortable="true" field="tlista" bodyStyle="text-align: center"/>
                <Column header="Tipo" :sortable="true" field="ttipo_lista" bodyStyle="text-align: center"/>
              </h1>
              <Column header="Codigo Validación" :sortable="true" field="tcodigo_validacion"
                      bodyStyle="text-align: right"/>

              <Column header="Estatus" :sortable="true" field="tstatus"
                      bodyStyle="text-align: center">
                <template #body="{data}">
                  <div :class="statusClass(data)">
                    {{ data.tstatus }}
                  </div>
                </template>
              </Column>
            </Row>
          </ColumnGroup>

        </DataTable>
        <!--          </AccordionTab>-->

        <!--        </Accordion>-->


      </div>
    </div>
  </Dialog>

  <ViewDialog
      :show="displayTicket"
      :ticket="ticket"
      page="sales"
      @close="closeViewTicketDialog"/>

</template>

<script>
// import Accordion from 'primevue/accordion';
import generalMixin from "@/mixins/generalMixin";
import TicketService from "@/service/backend/TicketService";
import ViewDialog from "@/components/Tickets/Dialogs/ViewDialog";

export default {
  name: 'TicketDetailDialog',
  mixins: [generalMixin],
  components: {
    // 'Accordion': Accordion,
    "ViewDialog": ViewDialog,
  },
  created() {
    this.ticketService = new TicketService;
  },
  mounted() {

  },
  data() {
    return {
      ticketData: [],
      loading1: false,
      loading2: false,
      ticket: {},
      displayTicket: false,
    }
  },
  props: {
    show: Boolean,
    header: String,
    status: String,
    game: String,
    data: Object,
  },

  methods: {
    closeDialog() {
      this.computedVisible = false;
      this.$emit('close-detail')
    },
    formatCurrency(value) {
      let response = null;
      if (value)
        response = Number(value).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return response;
    },
    statusClass(data) {
      return [
        {
          'anulado': data.tstatus === 'ANULADO',
          'ganador': data.tstatus === 'GANADOR',
        }
      ];
    },
    async openTicket() {
      this.loading2 = true;
      this.ticket = await this.ticketService.getTicket({
        id_transaccion: this.data[0].id_transaccion,
        fecha: this.data[0].fecha,
      });
      this.displayTicket = true;
    },
    closeViewTicketDialog() {
      this.loading2=false;
      this.displayTicket = false;
    }

  },

  computed: {
    getHeader() {
      return this.header;
    },
    computedVisible: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close-detail')
      }
    },

    getTotals() {
      let totalMonto = 0;
      if (this.data) {
        for (let item of this.data) {
          totalMonto += Number(item.mmonto_aceptado)
        }
      }
      return {
        numero: this.data.length,
        monto: totalMonto.toFixed(2)
      };
    }

  },
};
</script>
<style scoped lang="scss">
.ticket-detail-dialog .p-dialog-title {
  background-color: #004a49;
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
  color: white;
}

.title {
  background-color: #004a49;
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  font-size: medium;
}

.card {
  width: 100%;
}

::v-deep(.row-accessories) {
  background-color: rgba(0, 0, 0, .15) !important;
}

.p-inputgroup {
  margin: 5px;
}

.details {
  font-size: initial;
  border: 1px solid var(--primary-color);
  padding: 5px 5px 5px 15px;
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.p-fluid .p-inputgroup .p-button {
  min-width: 100px;
}


.ticket-detail-dialog {
  min-width: 1000px;
}

@media only screen and (max-width: 1000px) {
  .ticket-detail-dialog {
    min-width: unset;
  }

  .detail-accordion {
    min-width: unset
  }
}


</style>