<template>
  <div id="overlay" v-if="overlay"></div>

  <div class="grid"
    v-bind:style="{cursor: this.cursor}">
    <div class="col-12">
      <div class="card">
        <div class="block_container">
          <div class="bloc1">
            <div v-if="selectedGame === 'animal'">
              <h5>Animalitos en línea ({{ lista.length }})</h5>
            </div>
            <div v-else>
              <h5>Listas en línea</h5>
            </div>
          </div>
          <div class="bloc2"><h3>Total: {{ formatCurrency(Number(this.total)) }}</h3></div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-2">
      <div class="card p-fluid" id="number-container">

        <!--     Filter goes here     -->
        <Filter
            @get-data="getData"
            page="lists-online"
        />
      </div>
    </div>

    <div class="col-12 lg:col-10">


      <div class="card flex justify-content-center">

        <div id="overlay" v-if="loading1"></div>

        <h1 v-if=loading1>
          <div class="flex justify-content-center">
            <ProgressSpinner/>
          </div>
        </h1>
        <h1 v-else>
          <div class="col-12 lg:col-10">
            <div v-if="selectedGame2 === 'animal'">
              <div class="animal-container">
                <div v-for="(value, key) in lista" :key="key" style="padding:0 5px 0 5px ;" class="pill">
                  <Ball
                      class="t-animals"
                      :badge="`${zeroPad(value.id_elemento_lista,2)}-${value.animal}`"
                      :number="formatCurrency(value.venta)"
                      :style="getColour(value.venta)"
                      :lista="tipo"
                      game="animal"
                      @click="openTicket(value)"
                  />

                </div>
              </div>
            </div>
            <div v-else>
              <div :class="'number-container-' + getLayout()">
                <div v-for="(value, key) in lista" :key="key" style="padding:0 5px 0 5px;" class="pill">
                  <Ball
                      class="t-tickets"
                      :badge="value.nnumero"
                      :number="formatCurrency(value.sum)"
                      :style="getColour(value.sum)"
                      :lista="tipo"
                      game="lottery"
                      @click="openTicket(value)"
                  />
                </div>
              </div>
            </div>
          </div>
        </h1>
      </div>
    </div>
  </div>
  <TicketDialog
      :header="header"
      :data="data"
      :game="selectedGame"
      :show="openTicketDialog"
      @close="closeTicketDialog"/>

</template>
<script>

import ProgressSpinner from "primevue/progressspinner";
import {usePrimeVue} from "primevue/config";
import TicketDialog from "@/components/Sales/TicketDialog";
import generalMixin from "@/mixins/generalMixin";
import SaleService from "@/service/backend/SaleService";
import TicketService from "@/service/backend/TicketService";
import Filter from "@/components/Filters/Filter";
import Ball from "@/components/misc/Ball/Ball";

export default {
  mixins: [generalMixin],
  components: {
    TicketDialog,
    'Filter': Filter,
    'Ball': Ball,
    'ProgressSpinner': ProgressSpinner,
  },
  data() {
    return {
      overlay: false,
      loading1: false,
      loading2: false,
      componentKey: 0,
      filters: [],
      lista: [],
      total: 0,
      openTicketDialog: false,
      data: [],
      header: null,
      selectedGame: null,
      selectedGame2: null,
      tipo: 1,
      layoutClass: {
        0: 'terminal',
        1: 'triple',
        2: 'terminal-signo',
        3: 'triple-signo',
      },
      identity: null,
      selectedLista: null,
    }
  },
  saleService: null,
  created() {
    this.saleService = new SaleService();
    this.ticketService = new TicketService();
    this.identity = this.getIdentity();
  },
  async mounted() {
    const primevue = usePrimeVue();
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubrer", "Noviembre", "Diciembre"];
    primevue.config.locale.dayNamesMin = ["Do", "Lu", "Ma", "Mi", "Ju", "Vr", "Sa"];
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  methods: {
    async getData(filters) {
      this.overlay = true;
      this.loading1 = true;
      this.filters = filters;
      this.selectedGame = (filters.game) ? filters.game : 'lottery';
      this.selectedGame2 = this.selectedGame;
      this.selectedLista = filters.lista;
      filters.identity = this.getIdentity();
      const data = await this.saleService.getList(filters);

      this.lista = data.list;
      this.total = data.total;
      this.max = data.max;
      this.sorteos = data.draws;

      this.loading1 = false
      this.overlay = false;
      this.componentKey += 1;
    },
    async openTicket(data) {
      this.overlay = true;
      const filters = this.filters;
      // Set entity filter
      if (this.identity.roleId === 2)
        filters.comercializador = this.identity.entityId;
      if (this.identity.roleId === 3)
        filters.banca = this.identity.entityId;
      if (this.identity.roleId === 4)
        filters.distribuidor = this.identity.entityId;
      if (this.identity.roleId === 5)
        filters.agencia = this.identity.entityId;

      this.loading2 = true;
      this.header = (filters.game !== 'animal') ? data.nnumero : this.zeroPad(data.id_elemento_lista, 2) + ' ' + data.animal;
      this.data = await this.ticketService.getTicketReport({
        fecha: filters.fecha,
        sorteo: filters.sorteo,
        tipo: filters.tipo,
        lista: filters.lista,
        orderBy: filters.orderBy,
        // Filter
        comercializador: filters.comercializador ? filters.comercializador : null,
        distribuidor: filters.distribuidor ? filters.distribuidor : null,
        banca: filters.banca ? filters.banca : null,
        agencia: filters.agencia ? filters.agencia : null,
        numero: data.nnumero ? data.nnumero : null,
        // Animalitos
        game: filters.game,
        sorteoAnimalitos: filters.sorteoAnimalitos,
        orderByAnimalitos: filters.orderByAnimalitos,
        id_elemento_lista: data.id_elemento_lista,
        dtransaccion: data.dtransaccion ? data.dtransaccion : null,
      });
      this.loading2 = false;
      this.overlay = false;
      this.openTicketDialog = true;
    },
    closeTicketDialog() {
      this.openTicketDialog = false;
    },
    getLayout() {
      return this.layoutClass[this.selectedLista];
    }
  },
  computed: {
    calculateTotal() {
      let total = 0;
      if (this.groupByData) {
        for (let item of this.groupByData) {
          total += Number(item.amount)
        }
      }
      return this.formatCurrency(total);
    },
  }
}
</script>

<style scoped lang="scss">


::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

#block_container {
  text-align: center;
}

#block_container > div {
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
}

.refresh-button {
  float: right;
  background-color: #004a49;
  color: white;
  padding: 10px
}


.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 2px 0 5px 0;
}

.t-number {
  max-width: 100px;
  margin: 2px;
}


.number-container-triple {
  display: grid;
  grid-template: repeat(100, 1fr) / repeat(10, 1fr);
  grid-auto-flow: column dense;
}

.number-container-triple-signo {
  display: grid;
  grid-template: repeat(1200, 1fr) / repeat(10, 1fr);
  grid-auto-flow: column dense;
}

.number-container-terminal {
  display: grid;
  grid-template: repeat(20, 1fr) / repeat(5, 1fr);
  grid-auto-flow: column dense;
}

.number-container-terminal-signo {
  display: grid;
  grid-template: repeat(120, 1fr) / repeat(10, 1fr);
  grid-auto-flow: column dense;
}

.animal-container {
  display: grid;
  grid-template: repeat(19, 1fr) / repeat(2, 1fr);
  grid-auto-flow: column dense;

  :hover {
    cursor: pointer;
    background-color: var(--primary-color);
  }
}

@media only screen and (max-width: 1990px) {
  .number-container-triple {
    display: grid;
    grid-template: repeat(130, 1fr) / repeat(8, 1fr);
    grid-auto-flow: column dense;
  }
}

@media only screen and (max-width: 1800px) {
  .number-container-triple {
    display: grid;
    grid-template: repeat(200, 1fr) / repeat(50, 1fr);
    grid-auto-flow: column dense;
  }
  .number-container-triple-signo {
    display: grid;
    grid-template: repeat(2400, 1fr) / repeat(5, 1fr);
    grid-auto-flow: column dense;
  }
  .number-container-terminal-signo {
    display: grid;
    grid-template: repeat(240, 1fr) / repeat(5, 1fr);
    grid-auto-flow: column dense;
  }
}

@media only screen and (max-width: 1200px) {

  .number-container-terminal {
    display: grid;
    grid-template: repeat(50, 1fr) / repeat(2, 1fr);
    grid-auto-flow: column dense;
  }
}

@media only screen and (max-width: 670px) {
  .number-container-triple {
    display: grid;
    grid-template: repeat(250, 1fr) / repeat(40, 1fr);
    grid-auto-flow: column dense;
  }
  .number-container-triple-signo {
    display: grid;
    grid-template: repeat(6000, 1fr) / repeat(2, 1fr);
    grid-auto-flow: column dense;
  }
  .number-container-terminal-signo {
    display: grid;
    grid-template: repeat(600, 1fr) / repeat(2, 1fr);
    grid-auto-flow: column dense;
  }
}

@media only screen and (max-width: 500px) {
  .number-container-triple {
    display: grid;
    grid-template: repeat(500, 1fr) / repeat(20, 1fr);
    grid-auto-flow: column dense;
  }
  .number-container-terminal {
    display: grid;
    grid-template: repeat(50, 1fr) / repeat(2, 1fr);
    grid-auto-flow: column dense;
  }
}

.block_container {
  display: flex;
  justify-content: space-between;
}

.t-tickets {
  margin-bottom: 5px;
}

.t-animals {
  width: 175px !important;
  margin-bottom: 5px;

}

.pill {
  :hover {
    cursor: pointer;
    background-color: var(--primary-color);
  }
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: var(--primary-color);
  }
  40% {
    stroke: var(--primary-color);
  }
  66% {
    stroke: var(--primary-color);
  }
  80%,
  90% {
    stroke: var(--primary-color);
  }
}

</style>
