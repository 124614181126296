<template>
  <div :class="'number-' + getLayout()">
    <div :class="'badge-' + game">{{ getBadge(badge) }}</div>
    <div class="amount">{{ number }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Ball",
  props: {
    badge: String,
    number: String,
    lista: Number,
    game: String,
  },
  data() {
    return {
      layoutClass: {
        0: 'terminal',
        1: 'triple',
        2: 'terminal-signo',
        3: 'triple-signo',
      },
      zodiac: {
        0: 'ARIES',
        1: 'TAURO',
        2: 'GEMINIS',
        3: 'CANCER',
        4: 'LEO',
        5: 'VIRGO',
        6: 'LIBRA',
        7: 'ESCORPIO',
        8: 'SAGITARIO',
        9: 'CAPRICORNIO',
        A: 'ACUARIO',
        B: 'PISCIS',
      },
    }
  },
  methods: {
    getLayout() {
      let response = null;
      if (this.game === 'animal') {
        response = 'animal';
      } else {
        response = this.layoutClass[this.lista];

      }
      return response;
    },
    getBadge(value) {
      let response = 0;
      if (this.lista === 3) {
        response = value.slice(0, 3) + '-' + this.zodiac[value.substr(value.length - 1)].slice(0, 3);
      } else if (this.lista === 2) {
        response = value.slice(0, 2) + '-' + this.zodiac[value.substr(value.length - 1)].slice(0, 3);
      } else {
        response = value;
      }
      return response;
    }
  },
}
</script>

<style scoped lang="scss">
.number-triple, .number-terminal, .number-triple-signo, .number-terminal-signo, .number-animal {
  border: 1px solid;
  border-color: var(--primary-color);
  border-radius: 5px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  padding: 7px;
  flex-direction: row;

  .badge-lottery, .badge-animal {
    border: 1px solid black;
    border-radius: 7px;
    background-color: white;
    color: black;
    min-width: 40px;
    font-weight: bolder;
    text-align: center;
    font-size: small;
  }

  .amount {
    font-size: small;
    font-weight: normal;
  }
}

.number-triple-signo, .number-terminal-signo {
  min-width: 200px;
  .badge-lottery {
    min-width: 70px !important;
  }
}

.number-animal {
  min-width: 180px !important;
  .badge-animal {
    min-width: 80px !important;
    font-size: x-small !important;
    padding-top: 2px;
  }

}
</style>